<template>
	<ul v-if="items && visible(items)" role="menu">
        <template v-for="(item,i) of items">
            <li v-if="visible(item) && !item.separator" :key="item.label || i" :class="[{'topbar-active-menuitem': activeIndex === i && !item.disabled}]" role="none">
                <router-link v-if="item.to" :to="item.to" :style="item.style" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]" :target="item.target" exact
                    active-class="active-route" @click="onMenuItemClick($event,item,i)" role="menuitem" v-ripple>
                    <i :class="['layout-topbar-menuitem-icon', item.icon]"></i>
                    <span class="layout-topbar-menuitem-text">{{item.label}}</span>
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-topbar-submenu-toggler"></i>
                </router-link>
                <a v-if="!item.to" :href="item.url||'#'" :style="item.style" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]" :target="item.target"
					@click="onMenuItemClick($event,item,i)" v-ripple>
                    <i :class="['layout-topbar-menuitem-icon', item.icon]"></i>
                    <span class="layout-topbar-menuitem-text">{{item.label}}</span>
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-topbar-submenu-toggler"></i>
                </a>
				<transition name="layout-topbar-menu">
					<apptopbarsubmenu v-show="item.items && (root && activeIndex !== null) ? true : activeIndex === i" :items="visible(item) && item.items" :topbarMenuActive="topbarMenuActive"
						:parentMenuItemActive="activeIndex === i" @topbar-submenu-click="$emit('topbar-submenu-click', $event)"></apptopbarsubmenu>
				</transition>
            </li>
        </template>
	</ul>
</template>

<script>
export default {
	name: 'apptopbarsubmenu',
	emits: ['topbar-submenu-click'],
	props: {
		items: Array,
		topbarMenuActive: {
			type: Boolean,
			default: false
		},
		root: {
			type: Boolean,
			default: false
		},
		parentMenuItemActive: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			activeIndex : null
		}
	},
	methods: {
		onMenuItemClick(event, item, index) {
			if (item.disabled) {
				event.preventDefault();
				return;
			}

			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
                event.preventDefault();
			}

            if (item.items) {
                event.preventDefault();
            }

            if (item.items) {
                this.activeIndex = index === this.activeIndex ? null : index;
            }

			this.$emit('topbar-submenu-click', {
				originalEvent: event,
				item: item
			});
		},
        visible(item) {
            return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
		},
		getInk(el) {
			for (let i = 0; i < el.children.length; i++) {
				if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
					return el.children[i];
				}
			}
			return null;
		},
		removeClass(element, className) {
			if (element.classList)
				element.classList.remove(className);
			else
				element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	}
}
</script>

