<template>
	<div class="layout-topbar">
		<div class="layout-topbar-left">
			<router-link to="/" class="app-name">
				<img alt="main logo" class="app-logo" :src="'layout/images/logo-'+ (layoutColor === 'white' ? 'dark' : 'white') + '.png'">
	
			</router-link>

			<a href="#" class="menu-button" @click="onMenuButtonClick">
				<div class="menu-button-icon"></div>
			</a>

			<ul class="layout-topbar-main-menu">
				<li>
					<router-link to="/" exact-active-class="topbar-active-menuitem-routerlink">Anasayfa
						<span class="active-bottom"></span>
					</router-link>
				</li>
				<li>
					<router-link to='/Project/Detail' exact-active-class='topbar-active-menuitem-routerlink'>Proje
						<span class="active-bottom"></span>
					</router-link>
				</li>

                <li>
                    <div class='align-items-center justify-content-center surface-500 border-round text-white pl-3 pr-3 pt-2 pb-2 font-bold'>{{ activeProjectName }}</div>
                </li>
				
				
					


				<li :class="['layout-topbar-menu hidden', {'topbar-active-menuitem': topbarMenuActive}]">
					<a v-if="!menu[0].to" :style="menu[0].style" :class="[menu[0].class, 'p-ripple', {'p-disabled': menu[0].disabled}]" :target="menu[0].target"
						@click="onMenuItemClick($event,menu[0])" v-ripple>
						<i :class="['layout-menuitem-icon', menu[0].icon]"></i>
						<span class="layout-menuitem-text">{{menu[0].label}}</span>
						<i v-if="menu[0].items" class="pi pi-fw pi-angle-down layout-topbar-submenu-toggler"></i>
					</a>
					<AppTopbarMenu :items="menu[0].items" v-if="topbarMenuActive" :topbarMenuActive="topbarMenuActive" :parentMenuItemActive="true"
						@topbar-submenu-click="onTopbarMenuClick($event)" @topbar-submenuitem-click="onTopbarSubmenuItemClick($event)"></AppTopbarMenu>
				</li>
			</ul>
		</div>

		<div class="layout-topbar-right">

			<ul class="layout-topbar-actions">
		

				<li :class="['profile-item', {'active-topmenuitem fadeInDown': activeTopbarItem === 'profile'}]">
					<div class="profile-container" @click="onTopbarItemClick($event, 'profile')">
						<a href="#">{{ user.name.substr(0,1).toUpperCase() + user.surName.substr(0,1).toUpperCase() }}</a>
						<div class="name-container">
							<span class="name">{{ user.name }} {{ user.surName }}</span>
							<span class="job">{{ user.userType }}</span>
						</div>
						<i class="pi pi-angle-down"></i>
					</div>
					
					<ul class="profile-menu fadeInDown">
                        <li v-for="p in projects" :key='p.id' >
                           <a @click='changeActiveProject(p.id)'> <span> {{ p.name }}</span></a>
                        </li>
						<li>
							<a href="/#/logout">
								<span>Çıkış</span>
							</a>
						</li>
				
					</ul>
				</li>
			</ul>
		</div>			
	</div>
</template>

<script>
import AppTopbarMenu from './AppTopbarMenu';
import store from '@/store';
import { uuid } from 'vue-uuid';

export default {
	emits: ['menubutton-click', 'topbar-menu-click', 'topbar-item-click', 'topbar-submenu-click', 'search-item-click'],
	props: ['activeTopbarItem', 'topbarMenuActive', 'layoutColor'],
	data() {
		return {
            uuid: uuid.v1(),
            projects:[],
            activeProjectName:"",
			menu: [
				{
					label: 'Menu',
					items: [
						{
							label: 'UI Kit', icon: 'pi pi-align-left',
							items: [
								{label: 'Form Layout', icon: 'pi pi-id-card', to: '/formlayout'},
								{label: 'Input', icon: 'pi pi-check-square', to: '/input'},
							]
						},
						{
							label: 'Hierarchy', icon: 'pi pi-align-left',
							items: [
								{
									label: 'Submenu 1', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 1.1', icon: 'pi pi-align-left'},
										{label: 'Submenu 1.2', icon: 'pi pi-align-left'},
									]
								},
								{
									label: 'Submenu 2', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 2.1', icon: 'pi pi-align-left'},
									]
								},
							]
						}
					]
				}
			],
            user: {}
		}
	},
    created() {
        this.user = store.getters.getUser;
        this.projects = store.getters.getProjects;
        this.activeProjectName = store.getters.getActiveProject.name;
    },
    methods: {
        changeActiveProject(projectId) {
            let selectedProject = this.projects.filter((item) => {
                return item.id === projectId
            })[0];
            store.commit("setActiveProject", selectedProject);
            this.activeProjectName = selectedProject.name;
            this.$router.push({ name: 'refresh'});
        },
		onMenuButtonClick(event) {
			this.$emit('menubutton-click', event);
		},
		onTopbarItemClick(event, item) {
			if(item === 'search' && this.$refs.input1) {
				setTimeout(() => {
					this.$refs.input1.$el.focus();
					this.$refs.input2.$el.focus();
				}, 400);
			}

			this.$emit('topbar-item-click', {originalEvent: event, item: item});
			event.preventDefault();
		},
		onTopbarMenuClick(event) {
			this.$emit('topbar-submenu-click', event);
		},
		onTopbarSubItemClick(event) {
			event.preventDefault();
		},
		onTopbarSubmenuItemClick(event) {
			this.$emit('topbar-submenuitem-click', event);
		},
		onMenuItemClick(event, item) {
			if (item.disabled) {
				event.preventDefault();
				return;
			}

			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
                event.preventDefault();
			}

            if (item.items && this.topbarMenuActive) {
                event.preventDefault();
            }
			else {
				this.$emit('topbar-menu-click', event);
				event.preventDefault();
			}
		}
	},
	components: {
		AppTopbarMenu
	}
}
</script>
