
<template>
	<div :class="containerClass" @click="onDocumentClick">
		<AppTopBar :layoutColor="layoutColor" :activeTopbarItem="activeTopbarItem" :topbarMenuActive="topbarMenuActive"
			@menubutton-click="onMenuButtonClick" @topbar-menu-click="onTopbarMenuClick" @topbar-submenu-click="onTopbarSubmenuClick"
			@menuitem-click="onMenuItemClick" @topbar-item-click="onTopbarItemClick" @topbar-menuitem-click="onTopbarMenuItemClick" @search-item-click="onSearchItemClick"></AppTopBar>

		<AppMenu :model="menu" :menuMode="menuMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive"
			@menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>

		<div class="layout-content-wrapper">
			<div class="layout-content">
				<router-view></router-view>
			</div>
			
			<AppFooter :layoutColor="layoutColor" />

		</div>

		<AppConfig :menuMode="menuMode" :layoutColor="layoutColor" :lightMenu="lightMenu" :theme="theme" :themes="themeColors"
			@menu-mode-change="onMenuModeChange" @color-scheme-change="onColorSchemeChange" @layout-color-change="onLayoutColorChange"
			@theme-change="onThemeChange"></AppConfig>
	</div>
</template>

<script>
import AppTopBar from './components/shared/templateComponents/AppTopbar.vue';
import AppMenu from './components/shared/templateComponents/AppMenu.vue';
import AppFooter from './components/shared/templateComponents/AppFooter.vue';
import EventBus from './event-bus';
import AppConfig from "./components/shared/templateComponents/AppConfig";
import AdminMenuService from './services/adminMenuService';
import store from "@/store";

export default {
    _adminMenuService:null,
    created() {
        this._adminMenuService = new AdminMenuService();
    },
    async mounted() {
        if(store.getters.isLoggedIn)
        {
            let menuResponse = await this._adminMenuService.getAdminMenuWithRoles();
            this.menu = menuResponse.data;
        }
    },
	data() {
		return {
			menuMode: 'slim',
			theme: 'indigo',
			layoutColor: 'white',
			tempLayoutColor: 'white',
			lightMenu: true,
			staticMenuMobileActive: false,
			staticMenuDesktopInactive: false,
			menuClick: false,
			topbarMenuClick: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			menuActive: false,
			searchClick: false,
			themeColors: [
				{name: "Blue", file: "blue", color: "#0f97c7"},
				{name: "Blue Grey", file: "bluegrey", color: "#578697"},
				{name: "Brown", file: "brown", color: "#97664A"},
				{name: "Cyan", file: "cyan", color: "#1BA7AF"},
				{name: "Deep Orange", file: "deeporange", color: "#F96F43"},
				{name: "Deep Purple", file: "deeppurple", color: "#6952EC"},
				{name: "Green", file: "green", color: "#10B163"},
				{name: "Teal", file: "teal", color: "#4EA279"},
				{name: "Indigo", file: "indigo", color: "#435AD8"},
				{name: "Lime", file: "lime", color: "#A5B600"},
				{name: "Magenta", file: "magenta", color: "#B944D6"},
				{name: "Orange", file: "orange", color: "#E2841A"},
				{name: "Pink", file: "pink", color: "#E93A76"},
				{name: "Purple", file: "purple", color: "#9643F9"},
				{name: "Rose", file: "rose", color: "#AB5353"},
			],
            menu: [],		}
	},
	watch: {
		$route() {
			this.menuActive = false;
			this.$toast.removeAllGroups();
		}
	},
	methods: {
		onDocumentClick() {
			if (!this.topbarItemClick && !this.searchClick) {
				this.activeTopbarItem = null;
			}

			if (!this.topbarMenuClick) {
				this.topbarMenuActive = false;
			}

			if (!this.menuClick) {
				if (this.isSlim()) {
					this.menuActive = false;
                }

				if (this.staticMenuMobileActive) {
					this.hideOverlayMenu();
				}

				EventBus.emit('reset-active-index');
				this.unblockBodyScroll();
			}

			this.searchClick = false;
			this.topbarItemClick = false;
			this.topbarMenuClick = false;
			this.menuClick = false;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;
			this.topbarMenuActive = false;

			if (this.isDesktop())
				this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
			else {
				this.staticMenuMobileActive = !this.staticMenuMobileActive;
				if (this.staticMenuMobileActive) {
					this.blockBodyScroll();
				} else {
					this.unblockBodyScroll();
				}
			}

			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;
			

			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;
			
			event.originalEvent.preventDefault();
		},
		onSearchItemClick() {
			this.searchClick = true;
		},
		onTopbarMenuClick() {
			this.topbarMenuClick = true;
			this.topbarMenuActive = true;
		},
		onTopbarSubmenuClick(event) {
			this.topbarMenuClick = true;

			if (!event.item.items) {
				this.topbarMenuActive = false;
				EventBus.emit('reset-active-index');
			}
		},
		onTopbarMenuItemClick(event) {
			this.topbarItemClick = true;
			
			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;
			
			event.originalEvent.preventDefault();
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if (!event.item.items && this.isSlim()) {
				this.menuActive = false;
			}
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
		isTablet() {
			const width = window.innerWidth;
			return width <= 1024 && width > 640;
		},
		isDesktop() {
			return window.innerWidth > 896;
		},
		isMobile() {
			return window.innerWidth < 1025;
		},
		isSlim() {
			return this.menuMode === 'slim';
		},
		onMenuModeChange(menuMode) {
			this.menuMode = menuMode;
			this.staticMenuDesktopInactive = false;
		},
		onThemeChange(theme) {
			this.theme = theme;

			const themeLink = document.getElementById('theme-css');
			const href = 'theme/' + theme + '/theme-' + this.$appState.colorScheme + '.css';

			this.replaceLink(themeLink, href);
		},
		onLayoutColorChange(layout) {
			this.tempLayoutColor = layout;
			this.layoutColor = layout;

			this.changeStyleSheetUrl('layout-css', layout, 'layout');
		},
		onColorSchemeChange(newColorScheme) {
			this.$appState.colorScheme = newColorScheme;
			this.layoutColor = newColorScheme === 'dark' ? newColorScheme : this.tempLayoutColor;

			this.changeStyleSheetUrl('layout-css', this.layoutColor, 'layout');
			this.changeStyleSheetUrl('theme-css', newColorScheme, 'theme');
		},
		changeStyleSheetUrl(id, value, prefix) {
			let element = document.getElementById(id);
			let urlTokens = element.getAttribute('href').split('/');
			urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
			let newURL = urlTokens.join('/');

			this.replaceLink(element, newURL);
		},
		replaceLink(linkElement, href) {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);
			});
		},
		blockBodyScroll() {
			this.addClass(document.body, 'blocked-scroll');
		},
		unblockBodyScroll() {
			this.removeClass(document.body, 'blocked-scroll');
		},
		hideOverlayMenu() {
			this.staticMenuMobileActive = false;
		},
		addClass(element, className) {
			if (element.classList)
				element.classList.add(className);
			else
				element.className += ' ' + className;
		},
		removeClass(element, className) {
			if (element.classList)
				element.classList.remove(className);
			else
				element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	},
	computed: {
		containerClass() {
			return ['layout-wrapper', {
				'layout-slim': this.menuMode === 'slim',
				'layout-static': this.menuMode === 'static',
				'layout-mobile-active': this.staticMenuMobileActive,
				'layout-static-inactive': this.staticMenuDesktopInactive && this.menuMode === 'static',
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
			}];
		}
	},
	components: {
		'AppTopBar': AppTopBar,
		'AppMenu': AppMenu,
		'AppConfig': AppConfig,
		'AppFooter': AppFooter
	}
}
</script>
