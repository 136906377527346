import appAxios from '@/utils/appAxios'

const setup = (store) => {
    appAxios.interceptors.request.use(
        (config) => {
            const token = store.getters.getAccessToken;
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    appAxios.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== "/api/auth" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const refreshToken = store.getters.getRefreshToken;
                        const rs = await appAxios.post("/api/Auth/refresh-token", {
                            refreshToken: refreshToken,
                        });
                        const accessToken = rs.data.token;
                        const newRefreshToken = rs.data.refreshToken;
                        store.commit("setToken", accessToken);
                        store.commit("setRefreshToken", newRefreshToken);
                        return appAxios(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};
export default setup;