import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import moment from 'moment';

let ls = new SecureLS({ isCompression: false });

function cleanLoginDetail(state) {
  state.user = null;
  state.token = '';
  state.refreshToken = '';
  state.adminMenu = null;
  state.loginExpireDate = null;
  state.projects = null;
  state.activeProject = null;
}

export default createStore({
  state: {
    token: '',
    user: null,
    adminMenu: null,
    loginExpireDate: null,
  },
  getters: {
    isLoggedIn(state) {
      if(state.loginExpireDate == null)
      {
        cleanLoginDetail(state);
        return false;
      }

      let isLoginExpired =  moment().toDate() > moment(state.loginExpireDate).toDate();
      console.log(isLoginExpired);
      if(isLoginExpired)
      {
        cleanLoginDetail(state);
        return false;
      }
      return state.user?.id != null;
    },
    getUser(state) {
      return state.user;
    },
    getUserId(state) {
      return state.user.id;
    },
    getAccessToken(state) {
      return state.token;
    },
    getRefreshToken(state) {
      return state.refreshToken;
    },
    getAdminMenu(state) {
      return state.adminMenu;
    },
    getProjects(state) {
      return state.projects;
    },
    getActiveProject(state) {
      return state.activeProject;
    },
    checkLoggedUserIsExpired() {

    },
    getExpireDate(state) {
      return state.loginExpireDate
    }
  },
  mutations: {
    setUser(state, data) {
      state.user = {
        id: data.userData.id,
        name: data.userData.name,
        surName: data.userData.surName,
        email: data.userData.email,
        roles: data.userData.roles,
        userType: data.userData.userType
      };
    },
    setToken(state, data) {
      state.token = data;
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
    },
    setExpireDate(state, expireDate) {
      state.loginExpireDate = expireDate;
    },
    setAdminMenu(state, data) {
      state.adminMenu = data;
    },
    setProjects(state, projects) {
      state.projects = projects.userProjects;
      state.activeProject = projects.userProjects[0];
    },
    setActiveProject(state, project) {
      state.activeProject = project;
    },
    logout(state) {
      cleanLoginDetail(state);
    }
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})
