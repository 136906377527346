<template>
	<div class="layout-footer">
    <div class="footer-left">
   
    </div>
    <div class="footer-right">
        <span>© A1 Catering</span>
    </div>
</div>

</template>

<script>
	export default {
		name: "AppFooter",
		props: ['layoutColor']
	}
</script>

