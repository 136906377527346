import appAxios from '@/utils/appAxios'
import store from '../store';

export default class AdminMenuService {
    async getAdminMenuWithRoles() {
        let userId = store.getters.getUserId;
        const cachedAdminMenu = store.getters.getAdminMenu;
        if(cachedAdminMenu)
        {
          return {
            isSuccess:true,
            data: cachedAdminMenu,
            message:""
          };
        }
        const response = await appAxios.get('/bff/menu?UserId=' + userId);
        if(response.status === 200 && response.data.length > 0)
        {
          store.commit("setAdminMenu", response.data);
            return {
                isSuccess :true,
                data:response.data,
                message:""
            };
        }
        else{
            return {
                isSuccess: false,
                message: "Menu Yüklenemedi"
            }
        }

    }
}