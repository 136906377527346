import axios from 'axios';
import store from '@/store';

const token = store.getters.getAccessToken;

const appAxios = axios.create({
    baseURL: process.env.VUE_APP_MAIN_API,
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export default appAxios;