import {createRouter, createWebHashHistory} from 'vue-router';
import store from '@/store';
import App from '@/App.vue';
import 'material-symbols';

const routes = [
  {
    path: '/',
    name: 'app',
    component: App,
    children: [
      {
        path: '',
        name: 'dashboard',
        exact: true,
        component: () => import('@/components/Dashboard.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '',
        name: 'refresh',
        exact: true,
        component: () => import('@/pages/Refresh.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '',
        name: 'dashboard:randomSeed',
        exact: true,
        component: () => import('@/components/Dashboard.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '',
        name: 'home',
        exact: true,
        component: () => import('@/components/Dashboard.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ManagerUser',
        name: 'listManagerUser',
        exact: true,
        component: () => import('@/pages/managerUsers/list.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/ManagerUser/Add',
        name: 'addManagerUser',
        exact: true,
        component: () => import('@/pages/managerUsers/add.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/ManagerUser/Edit/:managerUserId',
        name: 'editManagerUser',
        exact: true,
        component: () => import('@/pages/managerUsers/edit.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/ManagerUser/Auth/:managerUserId',
        name: 'authManagerUser',
        exact: true,
        component: () => import('@/pages/managerUsers/auth.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/ManagerUser/Files/:managerUserId',
        name: 'fileManagerUser',
        exact: true,
        component: () => import('@/pages/managerUsers/files.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/ManagerUser/Pages/:managerUserId',
        name: 'pageManagerUser',
        exact: true,
        component: () => import('@/pages/managerUsers/pages.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Project/List',
        name: 'listProject',
        exact: true,
        component: () => import('@/pages/projects/list.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Project/Add',
        name: 'addProject',
        exact: true,
        component: () => import('@/pages/projects/add.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Project/Detail',
        name: 'detailProject',
        exact: true,
        component: () => import('@/pages/projects/detail.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Project/Edit/:projectId',
        name: 'editProject',
        exact: true,
        component: () => import('@/pages/projects/edit.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Project/Location/:projectId',
        name: 'editProjectLocation',
        exact: true,
        component: () => import('@/pages/projects/location.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Project/Official/:projectId',
        name: 'editProjectOfficial',
        exact: true,
        component: () => import('@/pages/projects/official.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Project/Files/:projectId',
        name: 'editProjectFiles',
        exact: true,
        component: () => import('@/pages/projects/files.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Project/Settings/:projectId',
        name: 'editProjectSettings',
        exact: true,
        component: () => import('@/pages/projects/settings.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Project/Defaults/:projectId',
        name: 'editProjectDefault',
        exact: true,
        component: () => import('@/pages/projects/defaults.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Firm/List',
        name: 'listFirm',
        exact: true,
        component: () => import('@/pages/firms/list.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Firm/DefaultList',
        name: 'listFirmDefaults',
        exact: true,
        component: () => import('@/pages/firms/defaultList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Invoice/FirmList',
        name: 'addFirmList',
        exact: true,
        component: () => import('@/pages/invoice/firmlist.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Invoice/FirmAdd',
        name: 'addInvoiceFirm',
        exact: true,
        component: () => import('@/pages/invoice/firmadd.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Invoice/InputInvoiceEntry',
        name: 'addInputInvoiceEntry',
        exact: true,
        component: () => import('@/pages/invoice/addInputInvoice.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Invoice/outputInvoiceEntry',
        name: 'addOutputInvoiceEntry',
        exact: true,
        component: () => import('@/pages/invoice/addOutputInvoice.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Invoice/Edit/:invoiceType/:invoiceId',
        name: 'editInvoice',
        exact: true,
        component: () => import('@/pages/invoice/editInvoice.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Invoice/InvoiceList/:invoiceType',
        name: 'invoiceListWithType',
        exact: true,
        component: () => import('@/pages/invoice/invoiceList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Invoice/InvoiceList',
        name: 'invoiceList',
        exact: true,
        component: () => import('@/pages/invoice/invoiceList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Invoice/InvoiceReport',
        name: 'invoiceReportList',
        exact: true,
        component: () => import('@/pages/invoice/invoiceReportList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Firm/Add',
        name: 'addFirm',
        exact: true,
        component: () => import('@/pages/firms/add.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Firm/Edit/:firmId',
        name: 'editFirm',
        exact: true,
        component: () => import('@/pages/firms/edit.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Firm/DefaultQuantities/:firmId',
        name: 'defaultQuantitiesFirm',
        exact: true,
        component: () => import('@/pages/firms/defaultQuantity.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Firm/Official/:firmId',
        name: 'editFirmOfficial',
        exact: true,
        component: () => import('@/pages/firms/official.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Firm/Files/:firmId',
        name: 'editFirmtFiles',
        exact: true,
        component: () => import('@/pages/firms/files.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Firm/Settings/:firmId',
        name: 'editFirmSettings',
        exact: true,
        component: () => import('@/pages/firms/settings.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Employee/List',
        name: 'listEmployee',
        exact: true,
        component: () => import('@/pages/employees/list.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Employee/Add',
        name: 'addEmployee',
        exact: true,
        component: () => import('@/pages/employees/add.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Employee/Edit/:employeeId',
        name: 'editEmployee',
        exact: true,
        component: () => import('@/pages/employees/edit.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Employee/Information/:employeeId',
        name: 'editEmployeeInformation',
        exact: true,
        component: () => import('@/pages/employees/information.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Employee/Contact/:employeeId',
        name: 'editEmployeeContact',
        exact: true,
        component: () => import('@/pages/employees/contact.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Employee/Files/:employeeId',
        name: 'editEmployeeFiles',
        exact: true,
        component: () => import('@/pages/employees/file.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/CalculateFoodPayment/:paymentDate',
        name: 'calculateFoodPaymentWithPaymentDate',
        component: () => import('@/pages/foodPayments/calculate.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/CalculateFoodPayment',
        name: 'calculateFoodPayment',
        component: () => import('@/pages/foodPayments/calculate.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ReportFoodPayment',
        name: 'reportFoodPayment',
        component: () => import('@/pages/foodPayments/report.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectReportFoodPayment',
        name: 'projectReportFoodPayment',
        component: () => import('@/pages/foodPayments/projectReport.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/Add',
        name: 'addProjectEmployee',
        component: () => import('@/pages/projectEmployees/add.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/Edit/:projectEmployeeId/:employeeId',
        name: 'editProjectEmployee',
        component: () => import('@/pages/projectEmployees/edit.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/Information/:projectEmployeeId/:employeeId',
        name: 'informationProjectEmployee',
        component: () => import('@/pages/projectEmployees/information.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/Contact/:projectEmployeeId/:employeeId',
        name: 'contactProjectEmployee',
        component: () => import('@/pages/projectEmployees/contact.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/Payment/:projectEmployeeId/:employeeId',
        name: 'paymentProjectEmployee',
        component: () => import('@/pages/projectEmployees/payment.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/WorkStatus/:projectEmployeeId/:employeeId',
        name: 'workStatusProjectEmployee',
        component: () => import('@/pages/projectEmployees/workStatus.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/Overtime/:projectEmployeeId/:employeeId',
        name: 'overtimeProjectEmployee',
        component: () => import('@/pages/projectEmployees/overtime.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/DayOff/:projectEmployeeId/:employeeId',
        name: 'dayOffProjectEmployee',
        component: () => import('@/pages/projectEmployees/dayOff.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/Allowance/:projectEmployeeId/:employeeId',
        name: 'allowanceProjectEmployee',
        component: () => import('@/pages/projectEmployees/allowance.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },

      {
        path: '/ProjectEmployee/Bonus/:projectEmployeeId/:employeeId',
        name: 'bonusProjectEmployee',
        component: () => import('@/pages/projectEmployees/bonus.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/Files/:projectEmployeeId/:employeeId',
        name: 'fileProjectEmployee',
        component: () => import('@/pages/projectEmployees/files.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/Salary/:projectEmployeeId/:employeeId',
        name: 'salaryProjectEmployee',
        component: () => import('@/pages/projectEmployees/salaries.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/SalaryHistory/:projectEmployeeId/:employeeId',
        name: 'salaryHistoryProjectEmployee',
        component: () => import('@/pages/projectEmployees/salaryHistory.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/List',
        name: 'listProjectEmployee',
        component: () => import('@/pages/projectEmployees/list.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/History',
        name: 'historyProjectEmployee',
        component: () => import('@/pages/projectEmployees/history.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },

      {
        path: '/ProjectEmployee/DayOffList',
        name: 'listProjectEmployeeDayOffs',
        component: () => import('@/pages/projectEmployees/dayOffList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/AllowanceList',
        name: 'listProjectEmployeeAllowances',
        component: () => import('@/pages/projectEmployees/allowanceList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/OvertimeList',
        name: 'listProjectEmployeeOvertimes',
        component: () => import('@/pages/projectEmployees/overtimeList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/Calculate',
        name: 'calculateProjectSalaries',
        component: () => import('@/pages/projectEmployees/calculate.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/ProjectEmployee/BonusList',
        name: 'listProjectEmployeeBonuses',
        component: () => import('@/pages/projectEmployees/bonusList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Accounting/AllowanceList',
        name: 'accountingEmployeeAllowanceList',
        component: () => import('@/pages/accounting/employeeAllowanceList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Accounting/BonusList',
        name: 'accountingEmployeeBonusList',
        component: () => import('@/pages/accounting/employeeBonusList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Accounting/EmployeeSalaryList',
        name: 'accountingEmployeeSalaryList',
        component: () => import('@/pages/accounting/employeeSalaryList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Accounting/EmployeeDayOffList',
        name: 'accountingEmployeeDayOffList',
        component: () => import('@/pages/accounting/employeeDayOffList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Accounting/ProjectEmployeeHistoryList',
        name: 'accountingProjectEmployeeHistoryList',
        component: () => import('@/pages/accounting/employeePersonalHistory.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Accounting/ProjectEmployeeOvertimeList',
        name: 'accountingEmployeeOvertimeList',
        component: () => import('@/pages/accounting/employeeOvertimeList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Accounting/EmployeeSalaryChangesList',
        name: 'accountingEmployeeSalaryChangesList',
        component: () => import('@/pages/accounting/employeeSalaryChanges.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Accounting/ProjectEmployeeList',
        name: 'accountingProjectEmployeeList',
        component: () => import('@/pages/accounting/projectEmployeeList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Company/Add',
        name: 'addCompany',
        component: () => import('@/pages/companies/add.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Company/Edit/:companyId',
        name: 'editCompany',
        component: () => import('@/pages/companies/edit.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Company/List',
        name: 'listCompany',
        component: () => import('@/pages/companies/list.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/Report/FoodPaymentReport',
        name: 'foodPaymentReport',
        component: () => import('@/pages/reports/foodPaymentReport.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/YeniPersonelEklemeVideo',
        name: 'YeniPersonelVideo',
        component: () => import('@/pages/videohelp/YeniPersonel.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/HakedisEklemeVideo',
        name: 'HakedisEklemeVideo',
        component: () => import('@/pages/videohelp/HakedisEkle.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/PersonelCikisVideo',
        name: 'PersonelCikisVideo',
        component: () => import('@/pages/videohelp/PersonelCikis.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn'] }
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Login.vue')
      },
      {
        path: '/error',
        name: 'error',
        component: () => import('@/pages/Error.vue')
      },
      {
        path: '/notfound',
        name: 'notfound',
        component: () => import('@/pages/NotFound.vue')
      },
      {
        path: '/access',
        name: 'access',
        component: () => import('@/pages/Access.vue')
      },
 


      {
        path: '/Messages/Add',
        name: 'addMessage',
        exact: true,
        component: () => import('@/pages/messages/add.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Messages/Read/:senderId/:receiverId/:messageId',
        name: 'readMessage',
        exact: true,
        component: () => import('@/pages/messages/read.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Messages/IncomingList',
        name: 'incomingMessages',
        exact: true,
        component: () => import('@/pages/messages/incomingList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Messages/OutgoingList',
        name: 'outgoingMessages',
        exact: true,
        component: () => import('@/pages/messages/sendList.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Sms/Send',
        name: 'sendSingleSms',
        exact: true,
        component: () => import('@/pages/sms/send.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Sms/SendToEmployee',
        name: 'employeeSendSms',
        exact: true,
        component: () => import('@/pages/sms/employeeSendSms.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Sms/SendToManager',
        name: 'managerSendSms',
        exact: true,
        component: () => import('@/pages/sms/managerSendSms.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
      {
        path: '/Sms/SendToFirm',
        name: 'firmSendSms',
        exact: true,
        component: () => import('@/pages/sms/firmSendSms.vue'),
        meta: { authRequired: true, expectedRoles: ['LoggedIn','Admin'] }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/pages/Logout.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/pages/Error.vue')
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('@/pages/NotFound.vue')
  },
  {
    path: '/access',
    name: 'access',
    component: () => import('@/pages/Access.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const userIsLoggedIn = await store.getters.isLoggedIn;

  let userRoleValid = true;

  if (userIsLoggedIn) {
    const userRoles = await store.getters.getUser.roles;
    const expectedRoles = to.meta.expectedRoles;

    if (expectedRoles != null) {
      let requiredRoles = expectedRoles.filter(
          (u) => !userRoles.map((r) => r).includes(u)
      );

      if (requiredRoles.length > 0) {
        userRoleValid = false;
      }
    }
  }


  if (to.meta.authRequired && !userIsLoggedIn) {
    next('/login')
  }
  else {
    if (!userRoleValid) {
      next("/access");
    }
    else {
      next();
    }
  }
});

export default router;